#participant-search-container {
    position: relative;
}

#participant-results {
    position: absolute;
    top: 80px;
    left: 0px;
    border: 1px solid #ccc;
    width: calc(100% - 20px);
    padding: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    background-color: white;
    z-index: 100;
}

#participant-search-container .avatar {
    padding: 5px;
}

#participant-search-container .avatar:hover {
    cursor: pointer;
    background-color: #eee;
}

[data-color-mode="dark"] #participant-results {
    background-color: #333;
    color: white;
}

[data-color-mode="dark"] #participant-search-container .avatar:hover {
    background-color: #444;
}
