.action-item {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid #e0e0e0;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.row.spaced {
  justify-content: space-between;
}

.priority-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.jira-icon {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}


.issue-title {
  margin-right: 8px;
  flex-grow: 1;
  word-break: break-word;
  text-align: left;
}

.issue-status {
  background-color: #f5f5f5;
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 8px;
}

.issue-key {
  font-size: 0.9rem;
  color: #888;
  margin-bottom: 16px;
}

.action-content {
  overflow: hidden;
  margin-bottom: 16px;
  text-align: left;
  word-break: break-word;
}

.action-icons {
  justify-content: flex-end;
}

.issue-type-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

[data-color-mode="dark"] .action-item {
  background-color: #333 !important;
}
