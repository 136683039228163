  .action-item {
    display: flex;
    margin-bottom: 10px;
  }
  
  .content {
    flex: 1;
    margin-right: 10px;
  }
  
  .jira-icon {
    display: flex;
    align-items: center;
    position: relative;
  }

  [data-color-mode="dark"] .action-item {
    background-color: #333 !important;
  }
