.option-container:hover {
    background-color: #f1f1f1;
    transition: background-color 0.3s ease;
}

html[data-color-mode="dark"] .selection-dark {
    color: #B6C2CF;
}

html[data-color-mode="dark"] .option-container:hover {
    color: #5d6369;
}