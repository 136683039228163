.required {
    color: red;
}

.participant-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.participant-item i {
    color: #172B4DBA;
    cursor: pointer;
}

.icons-container {
    display: flex;
    align-items: center;
}

.star-moderator {
    color: #ffd700 !important;
}

.draggable-column-item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

html[data-color-mode="dark"] .fa-trash {
    color: white !important;
}