.clickable {
    cursor: pointer !important;
}

html[data-color-mode="dark"] .moment-dark {
    color: #B6C2CF;
}

html[data-color-mode="dark"] .link-dark {
    color: #B6C2CF!important;
}

html[data-color-mode="dark"] .status {
    color: #B6C2CF !important;
}