  .participant {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .participant:hover {
    background-color: #f2f2f2;
  }
  
  .participants-container {
    display: flex;
    justify-content: space-between;
  }

  .participant-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .popup {
    position: absolute;
    top: calc(100% + 25px);
    left: -99px;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 9999;
  }

  .popup-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .popup-container {
    position: relative;
    display: inline-block;
  }

  .circle-avatar {
    position: relative;
    display: inline-block;
  }

  .circle-icon {
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 3px solid gray; 
  }

  .circle-icon.moderator {
    border: 3px solid #FFD700;
  }

  .discussing-wrapper {
    width: 100%;
  }

  @media (min-width: 1151px) {
      .discussing-wrapper {
          max-width: 1100px;
          margin-left: calc(50% - 550px);
          margin-right: auto;
      }
  }

  .closing-wrapper {
    width: 100%;
  }

  @media (min-width: 1151px) {
      .closing-wrapper {
          max-width: 1100px;
          margin-left: calc(50% - 550px);
          margin-right: auto;
      }
  }

  #grouping-container {
    padding-bottom: 30px;
  }

  #grouping-container * {
    user-select: none;
    -webkit-user-select: none; /* Para Safari y Chrome */
    -moz-user-select: none;    /* Para Firefox */
    -ms-user-select: none;     /* Para Internet Explorer/Edge */
  }

html[data-color-mode="dark"] p,
html[data-color-mode="dark"] span,
html[data-color-mode="dark"] div {
  color: white !important;
}